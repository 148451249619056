import React from 'react'
import Layout from '../components/layout'
import { Helmet } from 'react-helmet'
import Seo from '../components/seo'
import { graphql } from 'gatsby'
import FormContacto from "../components/formContacto"

import { useI18next, Trans } from 'gatsby-plugin-react-i18next'
import FaqItems from "../components/faq-items"

const FAQs = (props) => {

  const { language } = useI18next()


  return (
    <Layout location={props.location}>
      <Helmet bodyAttributes={{ class: 'page-header-black' }}></Helmet>

      <Seo title='Q&A' />
      <FaqItems />

      <section id='contacto' className='py-40 bg-black'>
        <div className='flex flex-col items-center justify-center max-w-3xl px-4 mx-auto text-center text-white lg:px-0'>
          <h2 className='mb-10 text-2xl font-extrabold lg:text-3xl'>
            <Trans>¿Aún tienes preguntas?</Trans>
            <br />
            <Trans>Escríbenos</Trans>.
          </h2>
          <FormContacto />
        </div>
      </section>
    </Layout>
  )
}

export default FAQs

export const query = graphql`
  query($language: String!) {
    locales: allLocale(
      filter: { ns: { in: ["common"] }, language: { eq: $language } }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`