import React, { useState, useRef, useEffect } from 'react'
import { gsap } from 'gsap/all'
// import { isBrowser } from 'react-device-detect'
import Chevron from '../images/chevron.inline.svg'

const collapsible = (node) => {
  const tl = gsap.timeline({
    defaults: {
      duration: 0.35,
      ease: ' power1.inOut',
    },
  })

  const animation = (height) =>
    tl
      .to(node, { height: height, willChange: 'height' })
      .to(node, { clearProps: 'willChange' }, '-=0.45')
      .pause()

  let open = () => animation('auto').play()
  let close = () => animation(0).play()

  return { open, close }
}

const Collapsible = (props) => {
  const [state, setState] = useState({
    initial: false,
    clicked: null,
  })

  let body = useRef(null)

  const handleClick = () => {
    if (state.initial === false) {
      setState({
        initial: null,
        clicked: true,
      })
    } else if (state.clicked === true) {
      setState({
        clicked: !state.clicked,
      })
    } else if (state.clicked === false) {
      setState({
        clicked: !state.clicked,
      })
    }
  }

  useEffect(() => {
    if (state.clicked === false) {
      collapsible(body).close()
    } else if (
      state.clicked === true ||
      (state.clicked === true && state.initial === null)
    ) {
      collapsible(body).open()
    }
  }, [state])

  return (
    <button
      className={props.className}
      onClick={() => handleClick()}
      //  {...props}
    >
      <header className='flex items-center'>
        <Chevron
          className={`mr-4 transform ${
            state.clicked === true ? 'rotate-0' : '-rotate-90'
          }`}
        />
        <h3 className='text-xl font-bold text-green'>{props.title}</h3>
      </header>
      {props.children && (
        <div className='h-0 min-h-0 overflow-hidden ' ref={(el) => (body = el)}>
          <div className='py-4'>{props.children}</div>
        </div>
      )}
    </button>
  )
}

export default Collapsible
