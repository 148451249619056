import React, { useState, useRef } from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import Collapsible from '../components/collapsible'
import Lupa from '../images/ico-lupa.inline.svg'

import { useI18next, Trans } from 'gatsby-plugin-react-i18next'

const FaqItems = (props) => {
  const { language } = useI18next()

  const data = useStaticQuery(graphql`
    {
      allWpFaq(
        sort: { order: ASC, fields: menuOrder }
        filter: { language: { code: { eq: ES } } }
      ) {
        nodes {
          title
          id
          content
          translations {
            language {
              code
              locale
            }
            title
            content
          }
        }
      }
    }
  `)
  // const { data } = props
  // Array of all news articles
  const allFaqs = data.allWpFaq.nodes

  const emptyQuery = ''
  const [state, setState] = useState({
    filteredData: [],
    query: emptyQuery,
  })

  const searchRef = useRef()

  const searchInput = searchRef.current
  const clearSearch = () => {
    setState({ query: '' })
    searchInput.value = ''
  }

  const handleInputChange = (event) => {
    const query = event.target.value
    // const { data } = props

    //// this is how we get all of our posts
    // const faqs = data.allWpFaq.nodes || []

    // return all filtered posts
    const filteredData = allFaqs.filter((faq) => {
      // destructure data from post frontmatter
      const { content, title } = faq
      return (
        // standardize data with .toLowerCase()
        // return true if the description, title or tags
        // contains the query string
        content.toLowerCase().includes(query.toLowerCase()) ||
        title.toLowerCase().includes(query.toLowerCase())
      )
    })

    // update state according to the latest query and results
    setState({
      query, // with current query string from the `Input` event
      filteredData, // with filtered data from posts.filter(post => (//filteredData)) above
    })
  }

  const { filteredData, query } = state
  const hasSearchResults = filteredData && query !== emptyQuery
  const faqs = hasSearchResults ? filteredData : allFaqs

  return (
    <section className='pt-40 pb-20 text-white bg-black border-b border-green'>
      <header className='pb-20 border-b border-green'>
        <div className='max-w-3xl px-4 mx-auto lg:px-0'>
          <h1 className='pb-10 font-extrabold text-center text-8xl'>Q&A</h1>

          <div className='flex border-b border-white'>
            <input
              ref={searchRef}
              id='searchInput'
              type='text'
              aria-label='Search'
              placeholder='Buscador de preguntas y respuestas'
              onChange={handleInputChange}
              className='w-full py-2 text-white placeholder-opacity-50 bg-transparent outline-none placeholder-grey'
            />
            <Lupa />
          </div>
        </div>
      </header>
      <div className='max-w-3xl px-4 pt-20 mx-auto lg:px-0'>
        <div>
          {faqs.map((faq) => {
            let title = faq.title
            let content = faq.content
            console.log(faq)

            faq.translations.forEach((trans) => {
              // console.log(trans)
              if (language.toUpperCase() === trans.language.code) {
                title = trans.title
                content = trans.content
              }
            })
            return (
              <Collapsible
                key={faq.id}
                className={`faq-item mb-6 text-left focus:outline-none cursor-pointer bg-none rotate text-2xl font-bold text-green`}
                title={title}
              >
                <div
                  className='text-base font-normal text-white contents'
                  dangerouslySetInnerHTML={{ __html: content }}
                />
              </Collapsible>
            )
          })}

          {state.query.length > 0 && state.filteredData.length === 0 && (
            <span className='block w-full mx-auto text-lg text-center text-green'>
              No se encontraron resultados.{' '}
              <button
                onClick={clearSearch}
                className='underline focus:outline-none'
              >
                Intenta una nueva búsqueda
              </button>
              .
            </span>
          )}
        </div>
      </div>
    </section>
  )
}

export default FaqItems